import React, { useState } from 'react';
import "./testimonials.css";
import {testimonialsData} from "../../data/testimonialsData";
import leftArrow from "../../img/leftArrow.png";
import rightArrow from "../../img/rightArrow.png";
import {motion} from "framer-motion";

const Testimonials = () => {

    const transition = {type: 'spring', duration : 3}
    
    const [selected, setSelected] = useState(0);
    const testilength = testimonialsData.length;


    const next = () =>{
        if(selected === testilength - 1){
         setSelected(0);
    
    }else{
        setSelected((prev) => prev + 1);
    }
}

    const pre = () => {
        if(selected === 0){
        setSelected(testilength - 1);
        }else{
        setSelected((prev) => prev - 1);
        }
    }

  return (

    <>
      <div className="testimonials" id='testimonials'>
        <div className="left-t">
            <span>Testimonials</span>
            <span className='stroke-text'>What they</span>
            <span>say about us</span>
            <motion.span 
            key={selected}
            initial={{opacity:0, x: -100}}
            animate={{opacity: 1, x:0}}
            exit={{opacity: 0, x: 100}}
            transition={transition}>
                {testimonialsData[selected].review}
            </motion.span>
            <span style={{fontSize : '14px'}}>
                <span style={{fontSize : '14px',fontWeight : 'normal'}}>
                {testimonialsData[selected].name} 
                    
                </span>{" "}
                 - {testimonialsData[selected].status}
            </span>
        </div>
        <div className="right-t">

            <motion.div 
            initial={{opacity:0, x: -100}}
            transition={{...transition, duration: 2}}
            whileInView={{opacity: 1, x:0}}
            className="div"></motion.div>
            <motion.div
            initial={{opacity:0, x: 100}}
            transition={{...transition, duration: 2}}
            whileInView={{opacity: 1, x:0}}
             className="div"></motion.div>

            <motion.img 
            key={selected}
            initial={{opacity:0, x: 100}}
            animate={{opacity: 1, x:0}}
            exit={{opacity: 0, x:-100}}
            transition={transition}
            src={testimonialsData[selected].image} alt="" />

            <div className="arrows">
                <img src={leftArrow} alt="" onClick={pre}/>
                <img src={rightArrow} alt="" onClick={next}/>
            </div>
        </div>
      </div>
    </>
  )
}

export default Testimonials;
