import React from 'react';
import "./footer.css";
import github from "../../img/github.png";
import insta from "../../img/instagram.png";
import linkedin from "../../img/linkedin.png";
import logo from "../../img/logo.png";



const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <hr/>
        <div className="footer">
        <div className="social-links">
            <img src={github} alt="" />
            <img src={insta} alt="" />
            <img src={linkedin} alt="" />
        </div>
        <div className="logo-f">
            <img src={logo} alt="" />
        </div>
        </div>
        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
      </div>
    </>
  )
}

export default Footer;
